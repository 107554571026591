import "../pages/index.css";
import { initialCards } from "./cards.js";
import { createCard } from "./card.js";
import { openModal, closeModal, closeWithEsc, closeWithOverlay } from "./modal.js";

const placesList = document.querySelector(".projects__list");
const cardTemplate = document.querySelector("#project-template").content.querySelector(".projects__list-item");

const textMoreContainer = document.querySelector(".about__text-more");
const textMoreButton = document.querySelector(".about__more-button");

const projectsList = document.querySelector('.projects__list');
const project = projectsList.querySelector('.projects__list-item-button');

const popupTransfer = document.querySelector('.popup_type_transfer');
const closePopupTransferButton = popupTransfer.querySelector('.popup__close');
const popupImage = popupTransfer.querySelector('.popup__content-image');
const popupTitle = popupTransfer.querySelector('h3');
const popupDesc = popupTransfer.querySelector('.popup__content-text');
const popupTransferlinkSite = popupTransfer.querySelector('.popup__transfer-link_type_site');
const popupTransferlinkGithub = popupTransfer.querySelector('.popup__transfer-link_type_github');

textMoreButton.addEventListener('click', () => toggleMoreContainer(textMoreContainer, textMoreButton));

closePopupTransferButton.addEventListener("click", () => closeModal(popupTransfer));

function toggleMoreContainer (container, button) {
    container.classList.toggle('about__text_collapsed');
    container.classList.toggle('about__text-more');
    button.textContent = container.classList.contains('about__text_collapsed') ? 'Свернуть -' : 'Развернуть +';
}

function transferCallback(name, desc, image, linkSite, linkGitHub) {
    openModal(popupTransfer);

    popupImage.src = image;
    popupImage.alt = name;

    popupTitle.textContent = name;
    popupDesc.textContent = desc;
    
    popupTransferlinkSite.href = linkSite;
    popupTransferlinkGithub.href = linkGitHub;
}

function renderCards(location, initialCards, transferCallback) {
    initialCards.forEach((itemCard) => {
        location.append(createCard(itemCard, cardTemplate, transferCallback));
    });
}

renderCards(placesList, initialCards, transferCallback);