function createCard(itemCard, cardTemplate, transferCallback) {
    const cardElement = cardTemplate.cloneNode(true);
    const buttonElement = cardElement.querySelector(".projects__list-item-button");
    const tagElement = cardElement.querySelector(".projects__list-item-tag");
    const titleElement = cardElement.querySelector("h3");
    const imageElement = cardElement.querySelector(".projects__list-item-img");
    const descElement = cardElement.querySelector(".projects__list-item-desc");

    imageElement.src = itemCard.image;
    imageElement.alt = `Превью проекта "${itemCard.name}"`;
    tagElement.textContent = itemCard.tag;
    titleElement.textContent = itemCard.name;
    descElement.textContent = itemCard.desc;

    if (itemCard.tag === "Учебный") tagElement.style.backgroundColor = "#55ADFF";
    if (itemCard.tag === "Pet-проект") tagElement.style.backgroundColor = "#DD55FF";

    buttonElement.addEventListener("click", () => { transferCallback(itemCard.name, itemCard.desc, itemCard.image, itemCard.linkSite, itemCard.linkGitHub) });

    return cardElement;
}

export { createCard };