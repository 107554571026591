const initialCards = [
    {
        name: "To-Do practic",
        desc: "Интерактивное веб-приложение для управления списками дел, созданное с акцентом на масштабируемость и лёгкую расширяемость функционала.",
        tag: "Pet-проект",
        image: `${require("../images/to-do-practic.png")}`,
        linkSite: "/projects/to-do-practic/",
        linkGitHub: "https://github.com/Andrpre/to-do-practic",
        stack: ["React", "Html", "CSS", "Git", "CRA"],
    },
    {
        name: "Место",
        desc: "Интерактивное веб-приложение, которое позволяет пользователям настраивать свой профиль, публиковать фотографиями различных мест, добавлять названия и ставить лайки.",
        tag: "Учебный",
        image: `${require("../images/mesto.png")}`,
        linkSite: "/projects/mesto-project-ff/",
        linkGitHub: "https://github.com/Andrpre/mesto-project-ff",
        stack: ["javaScript", "Html", "CSS", "Git", "Webpack"],
    },
    {
        name: "Закрывающий тег",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/zakrivayuschiy-teg-f.png")}`,
        linkSite: "/projects/zakrivayuschiy-teg-f/",
        linkGitHub: "https://github.com/Andrpre/zakrivayuschiy-teg-f",
    },
    {
        name: "Сложно сосредоточиться",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. В проекте применяется адаптивная верстка и присутствует переключение тем (темная/светлая). Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/slozhno-sosredotochitsya.png")}`,
        linkSite: "/projects/slozhno-sosredotochitsya/",
        linkGitHub: "https://github.com/Andrpre/slozhno-sosredotochitsya",
    },
    {
        name: "Карты подскажут",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. В проекте применяется адаптивная верстка и присутствует переключение тем (темная/светлая). Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/karty-podskazhut.png")}`,
        linkSite: "/projects/karty-podskazhut/",
        linkGitHub: "https://github.com/Andrpre/karty-podskazhut-main",
    },
    {
        name: "Посмотри в окно",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/posmotri-v-okno.png")}`,
        linkSite: "/projects/posmotri-v-okno/",
        linkGitHub: "https://github.com/Andrpre/posmotri_v_okno",
    },
    {
        name: "Нужно идеально",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/we-need-perfectly.png")}`,
        linkSite: "/projects/we-need-perfectly/",
        linkGitHub: "https://github.com/Andrpre/we-need-perfectly",
    },
    {
        name: "Оно тебе надо",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/ono-tebe-nado.png")}`,
        linkSite: "/projects/ono-tebe-nado/",
        linkGitHub: "https://github.com/Andrpre/ono-tebe-nado",
    },
];

export { initialCards };
